.state-lamps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: 750;
    border-color: white;
    border-radius: 15px;
    border-style: solid;
    padding: 20px;
}

.property-container {
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 24px;
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.property-value {
    border: solid;
    padding: 3px;
    text-align: center;
    height: 100%;
}

.property-container > p {
    margin: 0;
    margin-top: 5px;
}

.state {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.alarms {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    row-gap: 10px;
    color: white;
    padding: 15px;
    font-weight: 750;
    border-color: white;
    border-radius: 15px;
    border-style: solid;
}

.water-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.water-labels {
    border-color: white;
    border-radius: 15px;
    padding: 15px;
    color: white;
    font-weight: 600;
    font-size: clamp(1rem, 1.5vw, 2rem);
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
}