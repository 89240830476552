.inactive {
    background: grey;
    background-image: radial-gradient(lightgray, transparent);
    background-size: 5px 5px;
    width: clamp(1vw, 3.5rem, 4rem);
    height: clamp(1vw, 3.5rem, 4rem);
    border-radius: 50%;
    border: dotted 2px darkgray;
    box-shadow: 
      0 0 20px #111 inset,
      0 0 10px lightgray;
}

.green {
    background: green;
    background-image: radial-gradient(lime, transparent);
    background-size: 5px 5px;
    width: clamp(1vw, 3.5rem, 4rem);
    height: clamp(1vw, 3.5rem, 4rem);
    border-radius: 50%;
    border: dotted 2px greenyellow;
    box-shadow: 
    0 0 20px #111 inset,
    0 0 10px greenyellow;
}

.red{
    background: red;
    background-image: radial-gradient(brown, transparent);
    background-size: 5px 5px; 
    width: clamp(1vw, 3.5rem, 4rem);
    height: clamp(1vw, 3.5rem, 4rem);
    border-radius: 50%;
    border: dotted 2px red;
    box-shadow: 
      0 0 20px #111 inset,
      0 0 10px red;
  }
  .violet{
    background: darkviolet;
    background-image: radial-gradient(purple, transparent);
    background-size: 5px 5px; 
    width: clamp(1vw, 3.5rem, 4rem);
    height: clamp(1vw, 3.5rem, 4rem);
    border-radius: 50%;
    border: dotted 2px darkviolet;
    box-shadow: 
      0 0 20px #111 inset,
      0 0 10px darkviolet;
  }
  .yellow{
    background: yellow;
    background-image: radial-gradient(orange, transparent);
    background-size: 5px 5px;
    width: clamp(1vw, 3.5rem, 4rem);
    height: clamp(1vw, 3.5rem, 4rem);
    border-radius: 50%;
    border: dotted 2px yellow;
    box-shadow: 
      0 0 20px #111 inset,
      0 0 10px yellow;
  }
